<template>
  <div class="page">
    <!--pages/myaddressedit/myaddressedit.wxml-->
    <div class="list">
      <van-cell-group>
        <van-field
            v-model="real_name"
            clearable
            label="收货人"
            label-width="7em"
            placeholder="请输入姓名"
        />
      
        <van-field
            v-model="phone"
            clearable
            label="联系电话"
            label-width="7em"
            placeholder="请输入联系电话"
        />
      
        <van-field
            clearable
            v-model="area"
            @click="isShowArea=true"
            label="选择区域"
            label-width="7em"
            placeholder="点击选择所在区域"
        />
        <van-field
            v-model="detail"
            clearable
            label="详细地址"
            label-width="7em"
            placeholder="请填写决堤地址"
        />
      </van-cell-group>
    </div>
  
    <div class="desc">为保障您的商品准确送达，请填写正确的收货地址</div>
  
    <div class="choose-box flexcenter">
      <div class="f32b">设为默认地址</div>
      <i @click="setDefault()" :class="address.is_default?'iconfont iconxuanzhong':'iconfont iconweixuanzhong'"></i>
    </div>
  
    <div class="bottom flexcenter" v-if="address.id>0">
      <div class="item del" @click="removeAddress">删除地址</div>
      <div  class="item save" @click="saveAddress">更新地址</div>
    </div>
	<div class="bottom flexcenter" v-else>
	  <div  class="item add" @click="saveAddress">保存地址</div>
	</div>
	<van-popup v-model="isShowArea" position="bottom" :style="{ height: '40%' }">
		<van-area  @confirm="chooseArea" title="选择区域" :area-list="areaList" value="" />
	</van-popup>
  </div>
</template>

<script>
  import { areaList } from '@vant/area-data';
  
  export default {
    name: 'myaddressedit',
    data() {
      return {
        area: '',
        isShowArea: false,
        real_name:'',
        phone:'',
        detail:'',
        checked:false,
        address:{},
        regionData:[],//地址区域
        mode:'address',
        skuids:'',
        family:'', //判断是不是家庭单
        areaList,
      }
    },
    created() {
		
      let address = this.$route.query.address;
	  let mode = this.$route.query.mode;
	  let skuids = this.$route.query.skuids;
	  let family = this.$route.query.family;
	  
      if(typeof mode=='undefined'||mode!='checkout') {
        this.mode ='address'
      } else {
        this.mode ='checkout'
      }
      if(typeof address=="undefined"){
        console.log("添加地址信息");
      }
	  else
	  {
		  
		let addr = address;
		let _addr = JSON.parse(addr);

        this.address = _addr
        this.real_name = _addr.real_name
        this.phone = _addr.phone
        this.detail = _addr.detail
        this.checked = (_addr.is_default==1)?true : false
        this.regionData = [_addr.province,_addr.city,_addr.district]
		this.area = _addr.province?_addr.province+_addr.city+_addr.district:'选择省市区'
		console.log(_addr);
      }

      if(typeof mode!='undefined') {
        this.skuids = skuids
      }

      if(typeof family != 'undefined' && family=='1') {
        this.family = family
      }
    },
    methods: {
      chooseArea(e) {
        let [{name: name1}, {name: name2}, {name: name3}] = e
        this.isShowArea = false
		this.regionData = [name1,name2,name3];
        this.area = `${name1}${name2}${name3}`
      },
      //创建用户地址
      addAddress:function(){
        let that = this;
        this.post(
          this.API.addAddress,
          {
            real_name:that.real_name,
            phone:that.phone,
            region:that.regionData,
            detail:that.detail,
            default:that.checked
          }
        ).then(response => {
          if(response.data.err=='1'){
            this.$toast(response.data.msg)
          }else{
            this.$router.back()
          }
        })
      },
      //更新用户地址
      saveAddress:function(){
        let that = this;
        this.post(this.API.updateAddress,
          {
            id:that.address.id,
            real_name:that.real_name,
            phone:that.phone,
            region:that.regionData,
            detail:that.detail,
            default:that.checked
          }
        ).then(response => {
          if(response.data.err=='1'){
            this.$toast(response.data.msg)
          }else{
            this.$router.back()
          }
        })
      },
      //设为默认地址
      setDefault:function(){
		 
        if(typeof(this.address.id)=='undefined')
        {
          this.checked = !this.checked
          return;
        }
		
        let that = this;
        this.post(this.API.setDefault,
          {
            addr_id:that.address.id,
            default:!that.checked,
          }
        ).then(response => {
			console.log(response.data.err);
          if(response.data.err)
          {
            this.$toast(response.data.msg)
          }else{
			let _addr= this.address;
			_addr.is_default = !_addr.is_default;
			this.address = _addr;
          }
        })
      },
      //删除地址
      removeAddress:function(e){
        let that = this;
        this.$dialog.confirm({
			title: '提示',
			message: '是否删除此地址信息？',
		}).then(res => {
            this.post(
              this.API.removeAddress,
              {addr_id:that.address.id}
            ).then(response => {
              if(response.data){
                this.$router.back()
              }else{
                this.$toast('操作失败')
              }
            })
          })

      },
      //双向绑定input数据
      bindInputData:function(e){
        let _this = this;
        let dataset = e.currentTarget.dataset;
        let value = e.detail;
        let name = dataset.name;

        _this.data[name] = value;
        this.name = _this.data[name]

        console.log(_this.data[name]);
      },
      //改变选值
      bindRegionChange: function (e) {
        console.log('picker发送选择改变，携带值为', e.detail.value)
        this.setData({
          regionData: e.detail.value
        })
      },
    }
  };
</script>
<style>
	.van-area .van-picker__title {
		height:30px;
		line-height: 30px;
	}
</style>
<style lang="scss" scoped>
  .page {
    box-sizing: border-box;
    padding: 24px;
    min-height: 100%;
    background-color: #F5F5F5;
  }

  .list {
    border-radius: 6px;
    background-color: #FFFFFF;
  }
  .desc {
    margin: 24px 0;
    font-size: 24px;
    color: #777777;
  }
  .choose-box {
    justify-content: space-between;
    margin-bottom: 120px;
    padding: 24px;
    height: 102px;
    background-color: #FFFFFF;
  }

  .bottom {
    justify-content: space-between;
    position: fixed;
    left: 24px;
    right: 24px;
    bottom: 28px;
  }
  .bottom .item {
    width: 340px;
    height: 80px;
    line-height: 80px;
    border-radius: 40px;
    text-align: center;
    font-size: 32px;
    color: #FFFFFF;
  }
  .bottom .del {
    background-color: #DA4C40;
  }
  .bottom .save {
    background-color: #FCC601;
  }
  .bottom .add {
      width: 100%;
      background-color: #FCC601;
  }
</style>
